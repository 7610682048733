import React, { useCallback, useMemo, useState } from 'react';
import Select from '../Select/Select';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CsvBuilder } from 'filefy';
import moment from 'moment';
import { alphaNumSort } from '../../Utils';
import { useData } from '../../providers';

const pageSizeOptions = [
  { id: 10, label: 10 },
  { id: 20, label: 20 },
  { id: 30, label: 30 },
  { id: 50, label: 50 },
];

const defaultPageSize = 10;

export default function TableCard(props) {
  const [currentSort, setCurrentSort] = useState({ key: null, order: 'desc' });

  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState(1);
  const { t, i18n } = useTranslation('translation');
  const { data, rawCols, title } = props;
  const { orderSources } = useData();
  const history = useHistory();

  const formatLabel = useCallback(
    (data) => {
      /*istanbul ignore if*/
      if (props.disableLabelFormatter) {
        return data;
      }
      return data?.map((item) => {
        return Object.entries(item).reduce((obj, [key, value]) => {
          /*istanbul ignore if*/
          if (key === 'source') {
            const source = orderSources.find((os) => os.name === value);
            return {
              ...obj,
              source: source?.display_name || value,
            };
          }
          const source = orderSources.find((os) => os.name === key);

          return {
            ...obj,
            [source?.display_name || key]: value,
          };
        }, {});
      });
    },
    [orderSources, props.disableLabelFormatter]
  );

  const formatHeaderLabel = useCallback(
    (label) => {
      /*istanbul ignore if*/
      if (props.disableLabelFormatter) {
        return label;
      }
      const source = orderSources.find((os) => os.name === label);
      return source?.display_name || label;
    },
    [orderSources, props.disableLabelFormatter]
  );

  const currentPageData = useMemo(() => {
    let list = [...data];

    if (currentSort.key) {
      list = list.sort((a, b) => {
        if (currentSort.order === 'asc') {
          return alphaNumSort(a[currentSort.key], b[currentSort.key]);
        } else {
          return alphaNumSort(b[currentSort.key], a[currentSort.key]);
        }
      });
    }

    return list?.slice((pagination - 1) * pageSize, pagination * pageSize);
  }, [currentSort, pagination, pageSize, data]);

  function pagesOptions() {
    const options = [];
    for (let i = 0; i < Math.ceil(data?.length / pageSize); i++) {
      options.push({ id: i + 1, label: i + 1 });
    }
    return options;
  }

  function handlePageChange(event) {
    const selection = event.target.value;
    setPagination(parseInt(selection));
  }

  function handlePageSizeChange(event) {
    const selection = event.target.value;
    setPageSize(selection);
    setPagination(1);
  }

  function pageNb() {
    return Math.ceil(data?.length / pageSize);
  }

  function isMultiPage() {
    return data?.length > defaultPageSize;
  }

  function isLastPage() {
    return pagination >= pageNb();
  }

  function isFirstPage() {
    return pagination <= 1;
  }

  function goToNextPage() {
    if (!isLastPage()) {
      setPagination(pagination + 1);
    }
  }

  function goToPreviousPage() {
    if (!isFirstPage()) {
      setPagination(pagination - 1);
    }
  }

  const handleSort = (key) => {
    if (currentSort.key === key) {
      if (currentSort.order === 'desc') {
        setCurrentSort({ ...currentSort, order: 'asc' });
      } else {
        setCurrentSort({ key: null, order: 'desc' });
      }
    } else {
      setCurrentSort({ key, order: 'desc' });
    }
  };

  function TableLink(props) {
    return (
      <div
        className="flex-tbl-row-lnk table-link"
        onClick={() =>
          history.push(
            props.link.route + encodeURIComponent(props.datum[props.id_key])
          )
        }
        key={props.datum[props.id_key] + 'lnk'}
      >
        {props.children}
      </div>
    );
  }

  function TableLineData(props) {
    return (
      <div
        className={
          'flex-tbl-row flex-tbl-row-block flex-tbl-row-' +
          (props.columns.length + rawCols.length) +
          ' data'
        }
        key={props.datum[props.id_key]}
      >
        {props.columns.map((column, index) => {
          let cellClass = `cell js-cell-${index}`;
          if (props.sticky_cols > index) {
            cellClass = `${cellClass} cell-header sticky-cell sticky-cell-${index}`;
            if (
              index === props.sticky_cols - 1 ||
              index === props.columns.length - 1
            ) {
              cellClass += `${cellClass} last-sticky`;
            }
          }
          return (
            <div
              className={cellClass}
              key={column.key + column.label}
              data-testid={`row_${props.row}__${column.key}`}
            >
              <div className="is-hidden-desktop cell-label">{column.label}</div>
              {column.computeFct
                ? column.computeFct(
                    props.datum,
                    props.columns.filter((col) => col.key).map((col) => col.key)
                  )
                : column.formatter
                ? column.formatter(props.datum[column.key])
                : props.datum[column.key]}
            </div>
          );
        })}
        {rawCols.map((key, i) => (
          <div
            className={`cell js-cell-${i}`}
            key={props.datum[props.id_key] + key}
            style={props.datum.classes && props.datum.classes[key]}
          >
            <div className="is-hidden-desktop cell-label">{key}</div>
            {props.datum[key]}
          </div>
        ))}
      </div>
    );
  }

  function TableLine(props) {
    if (props.link) {
      return (
        <TableLink {...props}>
          <TableLineData {...props} />
        </TableLink>
      );
    } else {
      return <TableLineData {...props} />;
    }
  }

  const TableExport = ({ id }) => {
    const handleExport = () => {
      const columns = [...props.columns.map((c) => c.key), ...rawCols];
      const exportedData = data.map((rowData) =>
        columns.map((col) => {
          const computeFct = props.columns.find(
            (c) => c.key === col
          )?.computeFct;
          if (computeFct) {
            const [name] = computeFct(
              rowData,
              props.columns.filter((col) => col.key).map((col) => col.key)
            );
            return `${name.props.children}`;
          }
          return rowData[col];
        })
      );
      new CsvBuilder(
        `${title.replace(' ', '_')}_${moment().format('YYYY_MM_DD')}.csv`
      )
        .setDelimeter(i18n.language === 'fr-FR' ? ';' : ',')
        .setColumns(columns)
        .addRows(exportedData)
        .exportFile();
    };

    return (
      <>
        <div className="export-btn" key={`${id}-export-btn`}>
          <span
            aria-label={'export-to-csv-btn'}
            onClick={handleExport}
            className={'icon-btn export-btn'}
          >
            <img
              aria-label="export-to-csv"
              alt={t('common.exportToCSV')}
              src="/assets/icons/download.svg"
            />
          </span>
        </div>
      </>
    );
  };

  function TableNavigation({ id }) {
    if (isMultiPage()) {
      return (
        <>
          <div className="pagination-menu">
            {t('components.TableCard.page_size')}
            <Select
              disableTranslation
              options={pageSizeOptions}
              width="72px"
              label={null}
              handleChange={handlePageSizeChange}
              aria-label="page-size"
              selection={pageSize}
              key={`${id}-pagination`}
              id={`${id}-pagination`}
            />
            {t('components.TableCard.page')}
            <Select
              disableTranslation
              options={pagesOptions()}
              width="72px"
              label={null}
              aria-label="page-selector"
              handleChange={handlePageChange}
              selection={pagination}
              key={`${id}-page`}
              id={`${id}-page`}
            />
            <span aria-label="page-nb">{'/ ' + pageNb()}</span>
            <span
              onClick={goToPreviousPage}
              className={
                isFirstPage()
                  ? 'page-nav-icon'
                  : 'page-nav-icon page-nav-icon-active'
              }
            >
              <img
                aria-label="previous-page"
                src="/assets/icons/mini-chevron-left.svg"
              />
            </span>
            <span
              onClick={goToNextPage}
              className={
                isLastPage()
                  ? 'page-nav-icon'
                  : 'page-nav-icon page-nav-icon-active'
              }
            >
              <img
                aria-label="next-page"
                src="/assets/icons/mini-chevron-right.svg"
              />
            </span>
          </div>
        </>
      );
    } else {
      return <div></div>;
    }
  }

  const TableHeaderColumnSort = ({ column }) => {
    const jsSortClass = 'js-sort-button';

    if (currentSort.key === column) {
      if (currentSort.order === 'asc') {
        return <i className={`icon-arrow-up ${jsSortClass}`} />;
      } else {
        return <i className={`icon-arrow-down ${jsSortClass}`} />;
      }
    }
    return (
      <>
        <i className={`icon-arrow-down show-on-hover ${jsSortClass}`} />
      </>
    );
  };

  const TableHeaderColumn = ({ className, label, column, disableSort }) => {
    return (
      <>
        <div
          onClick={() => !disableSort && handleSort(column)}
          className={`cell cell-header ${className}`}
          style={{ cursor: !disableSort ? 'pointer' : 'auto' }}
        >
          {label}
          {!disableSort && <TableHeaderColumnSort column={column} />}
        </div>
      </>
    );
  };

  const TableHeader = () => {
    return (
      <>
        <div className="flex-tbl-headers">
          <div
            className={`flex-tbl-row flex-tbl-row-${
              props.columns.length + rawCols.length
            } is-hidden-touch'`}
          >
            {props.columns.map((header, index) => {
              let cellClass = `js-header-${index}`;
              if (props.sticky_cols > index) {
                cellClass = `sticky-cell sticky-cell-${index}`;
                if (
                  index === props.sticky_cols - 1 ||
                  index === props.columns.length - 1
                ) {
                  cellClass += ' last-sticky';
                }
              }
              return (
                <TableHeaderColumn
                  className={cellClass}
                  label={header.label}
                  key={`${props.title.replace(' ', '_')}-${header.key}`}
                  column={header.key}
                  disableSort={header.disableSort}
                />
              );
            })}
            {rawCols.map((key, index) => (
              <TableHeaderColumn
                className={`js-header-${index}`}
                label={formatHeaderLabel(key)}
                key={`${props.title.replace(' ', '_')}-${key}`}
                column={key}
              />
            ))}
          </div>
        </div>
      </>
    );
  };

  const ActionRow = ({ components, id }) => {
    return (
      <>
        <div className="flex-row">
          {components.map((Comp, index) => (
            <Comp
              id={`${id}-${Comp.name}-${index}`}
              key={`${id}-${Comp.name}-${index}`}
            />
          ))}
        </div>
      </>
    );
  };

  return (
    <span>
      <div className={'ContentCard-content'}>
        <ActionRow
          id={`${props.title.replace(' ', '_')}-action-row-up`}
          key={`${props.title.replace(' ', '_')}-action-row-up`}
          components={[TableNavigation, TableExport]}
        />
        <div className="flex-tbl">
          <TableHeader />
          {formatLabel(currentPageData).map((datum, i) => (
            <div
              className={`js-row-${i}`}
              key={`${props.title.replace(' ', '_')}-row-${i}`}
            >
              <TableLine
                datum={datum}
                row={i}
                {...props}
                key={`${props.title.replace(' ', '_')}-line-${i}`}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="ContentCard-footer">
        <ActionRow
          id={`${props.title.replace(' ', '_')}-action-row-down`}
          key={`${props.title.replace(' ', '_')}-action-row-down`}
          components={[TableNavigation]}
        />
      </div>
    </span>
  );
}
